import React from "react";

const qudoAlphaLogo = require("../../images/QUDO_LogoAlpha.svg");

export default function Loading() {
    return (
        <div
            className="text-center"
            style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)"
            }}
        >
            Loading...
            <br />
            <img src={qudoAlphaLogo} alt="qudo big logo" style={{ height: "8rem", marginTop: "1rem" }} />
        </div>
    );
}