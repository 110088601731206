import React from 'react';

const walletBeta = require("images/WalletMigrationIcons/WalletBeta.svg");
const walletChains = require("images/WalletMigrationIcons/WalletChains.svg");

function WalletMultichainIcon() {
  return (
    <div className="wallet-icon-container">
        <img src={walletBeta} alt='Wallet Beta button' className="wallet-icon" />
        <img src={walletChains} alt='Wallet Beta Chains' className="wallet-icon-chains" />
    </div>
  );
}

export default WalletMultichainIcon;