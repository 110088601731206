import React, { useState, useEffect }                   from "react";
import { useDispatch, useSelector }                     from 'react-redux'
import { ClipLoader }                                   from "react-spinners";
import { arrayToHex }                                   from "eosjs/dist/eosjs-serialize";
import * as actions                                     from "actions";

import { useEthereum, chains, findByChainId, rules }    from 'helpers/EthereumContext.js' 
import QUDOServer                                       from 'helpers/QUDOServerConnection';
import * as MK                                          from 'helpers/metakeep.js'
import sleep                                            from 'helpers/sleep'
import { confirmAmount }                                from "helpers/utils";

import { MessageModal }                                 from "components/components/modals/modals";
import WalletImportTutorial                             from "components/common/walletMigration/walletImportTutorial";


const send  = require("../../images/definitive/icn-send-qudos.png");
const arrow = require("../../images/definitive/icn-arrow-forward.png");

export default function Transfer({
	chainId			        = 0,     // Chain ID for the opened chain, where we execute actions / 0 = Telos Zero
    detailColor,	                 // Background shadow color
}) {
    const dispatch = useDispatch();
    const eth = useEthereum();
    const isEVM = chainId !== 0;

    const ual = useSelector(state => state.ual);
    const balance = useSelector(state => state.balance);
    const currentUser = useSelector(state => state.info);
    
    const [loading, setLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [successModal, setSuccessModal] = useState(false);
    const [failureModal, setFailureModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [quantity, setQuantity] = useState(0);
    const [maxBalance, setMaxBalance] = useState(0);

    const [chainSelected, setChainSelected] = useState(-1);

    const [tutorialModal, setTutorialModal] = useState(false);
    

    useEffect(() => {
        updateBalance();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        updateBalance();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [balance, eth.balances]);

    function updateBalance() {
        if(!isEVM){
            setMaxBalance(balance.balance);
            return;
        }
        if(isEVM){
            setMaxBalance(eth.balances[chainId]);
            return;
        }
    }

    // MARK: EVM submit
    async function submitEVM(){
        const flags = {
            "failed": false,    // if the action failed
            "waiting_update_old_balance": false, // if it's waiting for the update of the old balance, to proceed
        };
        if(global.isDev) console.log(`Send submit: EVM & Chain ID <${chainId}>`);
        if(chainId !== eth.network.chainId) console.error(`Send submit: Chain ID mismatch <${chainId}> != <${eth.network.chainId}> `);
        const chain = findByChainId(chainId);

        if( chainSelected !== 0 ) { // meaning, between any EVM
            if(global.isDev) console.log(`Send submit: between EVMs <${chainId}>`);
            try {
                await eth.ethersBridge(eth.walletAddress, quantity, chainSelected)
                .then(( tx )=>{
                    setModalMessage("Success. The balance will update shortly after");
                    setSuccessModal(true);
                    setTimeout(()=>{eth.updateBalances()}, 1300)
                })
                .catch((error) => {
                    console.error("Send submit error", error);
                    setModalMessage(error.message);
                    setFailureModal(true);
                });
            } catch (e) {
                console.error("Send submit error", e);
                setModalMessage(e.message);
                setFailureModal(true);
            }
            return;
        } else { // Meaning it's from EVM to Telos Zero    -   To bridge back, into original chain
            if(global.isDev) console.log(`Send submit: EVMs to Telos Zero <${chainId}> `);
            let stepsCompleted = "";

            if( quantity < rules.minimum_bridge_amount ){
                setModalMessage( `Failed, minimum amount to bridge is ${rules.minimum_bridge_amount} QUDO` );
                setFailureModal(true);
                return;
            }

            // From any chain to ZERO, we pass otherChain -> tEVM (to then tEVM -> ZERO)
            if( chainId !== chains.telosEVM.chainId ){ 
                // First bridge to Telos EVM, if not there already
                await eth.ethersBridge(eth.walletAddress, quantity, chains.telosEVM.chainId)
                .then(( tx )=>{ stepsCompleted += `-> Bridged from ${chain.name} to ${chains.telosEVM.chainName} `; })
                .catch(( error ) => { console.error("Transfer.submit.toZero.bridgeToEVM.error", error, stepsCompleted);
                    setModalMessage( error.message );
                    setFailureModal(true);
                    flags["failed"] = true;
                });
                if( flags["failed"] ) { return }

                // switch to EVM
                await eth.switchNetwork( chains.telosEVM.chainId )
                .then(()=>{ stepsCompleted += `-> Switched to ${chains.telosEVM.chainName} from ${chain.name} `; })
                .catch(( error ) => { console.error("Transfer.submit.toZero.switchNetwork.error", error, stepsCompleted);
                    setModalMessage( "Your tokens are now only at EVM. Reason:" + error.message );
                    setFailureModal(true);
                    eth.switchNetwork( chainId ); setTimeout(()=>{eth.updateBalances()}, 1300)
                    flags["failed"] = true;
                });
                if( flags["failed"] ) { return }
                
                const oldBalance = eth.balances[chains.telosEVM.chainId]; // get the old balance on telosEVM, we wait till it changes
                let currBalance = oldBalance
                if( currBalance < quantity){
                    flags["waiting_update_old_balance"] = true;

                    let i = 0; // TODO timeout after 1 minute
                    while(oldBalance === currBalance){ // wait for it to update on the chain.
                        try{
                            currBalance = await eth.getBalanceOf( eth.walletAddress, chains.telosEVM.chainId )
                        } catch(e) {
                            console.warn("Wallet.transfer.submit.evm.currBalance: ", e)
                        }
                        await sleep(1000);
                        i++
                        console.log(`[${i}] old vs new = <${oldBalance}> vs <${currBalance}>`)
                    }
                    flags["waiting_update_old_balance"] = false;
                } else {
                    console.log(`Transfer - Skipping the waiting, as curr <${currBalance}> is enough for <${quantity}>`)
                }
            }
            
            // Ask for permission to spend the user's tokens on the EVM Zero
            await eth.permissionToBridgeBack( quantity )
            .then(( tx )=>{ 
                stepsCompleted += `-> Gave the Bridge contract permission to use ${quantity}`; 
            })
            .catch(( error ) => { console.error("Transfer.submit.toZero.permission.error", error, stepsCompleted);
                setModalMessage( error.message );
                setFailureModal( true );
                flags["failed"] = true;
            });

            if( flags["failed"] ) { return }
            
            // Call to use the tokens from the permission before
            await eth.ethersBridgeBack(quantity, currentUser.account_name)
            .then(( tx )=>{ stepsCompleted += `-> Bridged back to Telos Zero, now we wait`; })
            .catch(( error ) => { console.error("Transfer.submit.toZero.bridgeBack.error", error, stepsCompleted);
                setModalMessage( error.message );
                setFailureModal(true);
                flags["failed"] = true;
            });

            if( flags["failed"] ) { return }

            setModalMessage("Success. You will receive your tokens on Telos Zero account in 30 to 60 seconds");
            setSuccessModal(true);

            // switch back to initial Network + update the balance after a while
            eth.switchNetwork( chainId );
            setTimeout(()=>{eth.updateBalances()}, 1300)

            console.log(`Transfer.submit.toZero.success`, stepsCompleted);
            return;
        } // bridge back to Telos Zero - completed
    }

    // MARK: TLOS submit
    /// From TLOS -> EVMs
    async function submitTLOS(){
        // receiver should be the bridge !
        const receiver = process.env.REACT_APP_CONTRACT_ACCOUNT_ZERO_BRIDGE;
        const receiverInfo = null;

        if( 1 > await eth.getBridgerNativeBalance() ){
            setModalMessage("Not enough funds on Qudo Bridge, try again later")
            setFailureModal(true)
            return;
        }

        const flags = {
            "sign_TLOS_transaction_failed": false,
        }

        if(currentUser.qudoManaged){
            try{
                // Already have the Metakeep released!
                if(process.env.REACT_APP_METAKEEP_RELEASED === 'true' || currentUser.email === global.testAccEmail){
                    const account = process.env.REACT_APP_TOKENCONTRACT;
                    const actions = [{
                        "account": account,
                        "name": "transfer",
                        "authorization": [{
                            "actor": currentUser.account_name,
                            "permission": "active"
                        }],
                        "data": {
                            from: currentUser.account_name,
                            to: receiver,
                            quantity: `${Number(quantity).toFixed(4)} ${process.env.REACT_APP_TOKENNAME}`,
                            memo: `${eth.walletAddress}`,
                        }
                    }];

                    const MK_signResponse = await MK.signTransaction(currentUser.email, actions, {broadcast: false})
                    const backendSent = await QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/eos/send`, {
                        from: currentUser.account_name,
                        to: receiver,
                        to_username: receiverInfo ? receiverInfo.username : null,
                        quantity: quantity,
                        memo: `${eth.walletAddress}`,
                        signature: MK_signResponse.unpackedTransaction,
                    }, { withCredentials: true } )
                } 
                
                else
                // Metakeep not yet released
                { // Previous version, without metakeep // TODO: DELETE WHEN METAKEEP FULLY RELEASED
                    const backendSignedTrans = await QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/eos/send`, {
                        from: currentUser.account_name,
                        to: receiver,
                        to_username: receiverInfo ? receiverInfo.username : null,
                        quantity: quantity,
                        memo: `${eth.walletAddress}`,
                        signature: null,
                    }, {  withCredentials: true } )
                }
            } catch(e) {
                flags.sign_TLOS_transaction_failed = true
                setModalMessage(e.errorMessage)
                setFailureModal(true)
            }
        } else {
            try{
                const signedData = await ual.activeUser.signTransaction({
                    actions: [{
                        account: process.env.REACT_APP_TOKENCONTRACT,
                        name: "transfer",
                        authorization: [{
                            actor: currentUser.account_name,
                            permission: "active"
                        }],
                        data: {
                            from: currentUser.account_name,
                            to: receiver,
                            quantity: `${Number(quantity).toFixed(4)} ${process.env.REACT_APP_TOKENNAME}`,
                            memo: `${eth.walletAddress}`
                        }
                    }]
                }, {
                    blocksBehind: 3,
                    expireSeconds: 30,
                    broadcast: false
                });

                const signatureData = {
                    signatures: signedData.transaction.signatures,
                    packed_trx: arrayToHex(signedData.transaction.serializedTransaction)
                }
                const backendSubmit = await QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/eos/send`, {
                    from: currentUser.account_name,
                    to: receiver,
                    to_username: receiverInfo ? receiverInfo.username : null,
                    quantity: quantity,
                    memo: "Transfer",
                    signature: signatureData
                }, {
                    withCredentials: true
                })
            } catch (e) {
                flags.sign_TLOS_transaction_failed = true
                setModalMessage(e.errorMessage)
                setFailureModal(true)
            }
        }

        if(flags.sign_TLOS_transaction_failed)
            return;
        
        dispatch( actions.updateBalance() )

        // from ZERO to TEVM only ? 
        if(chainSelected === chains.telosEVM.chainId){
            setModalMessage("Success");
            setSuccessModal(true);
            return;
        }

        const oldBalance = eth.balances[chains.telosEVM.chainId]; // get the old balance on telosEVM, we wait till it changes
        let currentBalance = oldBalance + 0;
        await sleep(1000)
        let i = 0;
        // TODO timeout after 30 seconds ? slowly increase the sleep by i * 100 or something
        while(oldBalance === currentBalance){ // wait for it to update on the chain.
            try {
                currentBalance = await eth.getBalanceOf( eth.walletAddress, chains.telosEVM.chainId )
            } catch (error) {
                console.warn("Wallet.transfer.submit.evm.currBalance: ", error)
            }
            await sleep(1000);
            i++
            console.log(`[${i}] old vs new = <${oldBalance}> vs <${currentBalance}>`)
        }
        // Ok, bridged tokens to tEVM, now omniChain them between chaisn
        
        if(global.isDev) console.log(`Slept 1s, submit: between EVMs <${chainId}>`);

        try {
            await eth.ethersBridge(eth.walletAddress, quantity, chainSelected)
            .then(( tx )=>{
                setModalMessage("Success. The balance will update shortly after");
                setSuccessModal(true);
            })
            .catch((error) => {
                console.error("Send submit error on omniChain", error);
                setModalMessage(error.message);
                setFailureModal(true);
            });
        } catch (e) {
            console.error("Send submit error omniChain 2", e);
            setModalMessage(e.message);
            setFailureModal(true);
        } 
    }

    // MARK: Submit
    async function submit() {
        if(chainSelected === -1) { setErrorMessage("Please select a chain"); return; }
        if(quantity <= 0) { setErrorMessage("Please enter a positive amount"); return; }
        if(quantity > maxBalance) { setErrorMessage("Insufficient balance"); return; }
        setLoading(true);                
        
        if(isEVM) {
            await submitEVM();
        } else {
            await submitTLOS();
        }
        setLoading(false);
        
    }

    
    // MARK: Component
    return (
    <>
        <div className="card roundcard shadow" style={{ borderColor:`${detailColor?detailColor:''}` }} >
            <div className="row px-4 pt-4 pb-2">
                <div className="col-1">
                    <img src={send} className="mr-2" alt="send"/>
                </div>
                <div className="col-10">Transfer</div>
            </div>

            {!currentUser.qudoManaged && !ual.activeUser && !isEVM ?  // TODO: Another of this to check for eth
            ( <>
            <div className="card-body px-4 pb-0 pt-3">
                Please sign in with one of the available wallets to transfer QUDO
            </div>
            <div className="card-body">
                <button
                    type="button"
                    className="std-button-active"
                    onClick={() => {
                        ual.logout();
                        ual.restart();
                        ual.showModal();
                    }}
                >
                    <div className="text-center">
                        SIGN IN
                    </div>
                </button>
                <button
                    type="button"
                    className="std-button mb-0"
                    style={{backgroundColor: '#00cc69'}}
                    onClick={() => setTutorialModal(!tutorialModal)}
                >
                    <div className="text-center">
                        HOW TO SIGN IN
                    </div>
                </button>
            </div>
            </> ) : ( <>
        
                <div className="card-body px-4 pb-4 pt-0" >
                    <span>
                        Move my tokens across my QUDO chains
                    </span>

                    <div style={{margin:"12px 80px 12px 80px"}}>
                    <div className="mt-3 text-center">
                        <div className={`network-selector ${chainId === 0?'network-disabled':''}`}>
                            <label className="network-item">
                                Telos ZERO
                            </label>
                            <input
                                type="radio"
                                className="my-auto"
                                disabled={chainId === 0}
                                checked={chainSelected === 0}
                                onChange={() => setChainSelected(0)}
                            />
                        </div>
                        <div className={`network-selector ${chainId === chains.telosEVM.chainId?'network-disabled':''}`}>
                            <label className="network-item">
                                Telos EVM
                            </label>
                            <input
                                type="radio"
                                className="my-auto"
                                disabled={chainId === chains.telosEVM.chainId}
                                checked={chainSelected === chains.telosEVM.chainId}
                                onChange={() => setChainSelected(chains.telosEVM.chainId)}
                            />
                        </div>
                        <div className={`network-selector ${chainId === chains.base.chainId?'network-disabled':''}`}>
                            <label className="network-item">
                                BASE
                            </label>
                            <input
                                type="radio"
                                className="my-auto"
                                disabled={chainId === chains.base.chainId}
                                checked={chainSelected === chains.base.chainId}
                                onChange={() => setChainSelected(chains.base.chainId)}
                            />
                        </div>
                    </div>
                    </div>

                    {errorMessage && (
                        <div className="row mt-3" style={{color: 'red', fontSize: '0.85rem'}}>
                            <div className="col-12 text-center">
                                {errorMessage}
                            </div>
                        </div>
                    )}
                </div>

                <div className="card-body" style={{padding: "0 3rem"}}>
                    <div className="row justify-content-center">
                        <div className="col-12">Amount</div>
                    </div>
                    <div className="row justify-content-center" style={{borderBottom:`1px solid ${detailColor?detailColor:'#00000020'}`}}>
                        <input
                            type="number"
                            name="quantity"
                            className="col-12 col-xl-8 numbered-range-input"
                            style={{border: "none"}}
                            step={0.01}
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                            onBlur={() => confirmAmount(quantity, maxBalance, 0, setQuantity)}
                        />
                        <div className="col-12 col-xl-4 justify-content-right">
                            Qudo
                        </div>
                    </div>
                </div>

                <div className="card-body">
                    {loading ? (
                        <div className="text-center">
                            <ClipLoader color="#282725"/>
                        </div>
                    ) : (
                        <button
                            onClick={() => { submit(); }}
                            type="button"
                            className="std-button-active"
                        >
                            <div className="text-center">
                                <div style={{ float: "left" }}>
                                    SEND QUDO
                                </div>
                                <img
                                    style={{ float: "right" }}
                                    src={arrow}
                                    alt="arrow"
                                />
                            </div>
                        </button>
                    )}
                </div>
        
            </> )
            }
        </div>

        <MessageModal
            show={successModal} 
            message={modalMessage}
            hide={() => setSuccessModal(!successModal)}
        />

        <MessageModal
            show={failureModal} 
            message={modalMessage}
            hide={() => setFailureModal(!failureModal)}
        />
        
        {tutorialModal && (
            <WalletImportTutorial
                show={tutorialModal}
                hide={() => setTutorialModal(!tutorialModal)}
            />
        )}
    </>
    )
}