import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Modal} from 'react-bootstrap';
import Moment from 'moment-timezone';
import Highcharts from 'highcharts';

import WalletMigrationCard from './walletMigration/walletMigrationCard';

import QUDOServer from '../../helpers/QUDOServerConnection';

import arrow from '../../images/definitive/icn-arrow-forward.png';

function balanceNumbers(balanceNum, index) {
    if (index === 0) {
        return (
            <span key={balanceNum + '+' + index}>
                {balanceNum}
            </span>
        );
    } else {
        return (
            <span key={balanceNum + '+' + index} className='balancedecimals'>
                .{balanceNum}
            </span>
        );
    }
}

function InfoButtons() {
    const [button, setButton] = useState('');

    function modals() {
        return(
            <>
            <Modal show={button === 'howToBuy'} onHide={() => setButton('')}>
                <Modal.Header closeButton className='modal-header-position'/>

                <div className="row" style={{height: "100%", overflow: "hidden"}}>
                    <div className="col-12">
                        <div className="mx-auto gamedetails-image-container" style={{padding: 0}}>
                            <div className="mb-4 mt-5">
                                <div className="section-title text-center">How to get QUDO</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-11 mb-5 mx-auto" style={{overflow: "auto"}}>
                        <div className="mb-3">
                            Since QUDO is still on a test net, current QUDO tokens are test tokens with no market value. 
                            QUDO is anticipated to go live on the main net very soon. QUDO token price 
                            will be determined by market dynamics when launched in the main net.
                        </div>
                        <div className="mb-3">
                            We successfully finished the QUDO tokens seed round, and the private sale is already underway. 
                            If you want to be eligible for the token private sale or if you have any questions, please 
                            contact us at

                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="mailto:qudo.token@blockbastards.io"
                                className="d-inline-block mx-1"
                            >
                                qudo.token@blockbastards.io.
                            </a>
                        </div>
                        <div>
                            You can also join the upcoming public token sale by subscribing to the QUDO whitelist to be 
                            among the first ones to receive access to the QUDO public sale. Meanwhile, we highly advise 
                            you to join our

                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://discord.gg/SwmdFkMhhe"
                                className="d-inline-block mx-1"
                            >
                                Discord
                            </a>

                            and

                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://t.me/qudocoin"
                                className="d-inline-block mx-1"
                            >
                                Telegram
                            </a>

                            channels as it will be where we will announce the QUDO whitelist and to remain up to date.
                        </div>
                    </div>  
                </div>
            </Modal>

            <Modal show={button === 'howToSell'} onHide={() => setButton('')}>
                <Modal.Header closeButton className='modal-header-position'/>

                <div className="row" style={{height: "100%", overflow: "hidden"}}>
                    <div className="col-12">
                        <div className="mx-auto gamedetails-image-container" style={{padding: 0}}>
                            <div className="mb-4 mt-5">
                                <div className="section-title text-center">How to exchange QUDO</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-11 mb-5 mx-auto" style={{overflow: "auto"}}>
                        <div className="mb-3">
                            Since QUDO is still on a test net, current QUDO tokens are test tokens with no market value. 
                            QUDO is anticipated to go live on the main net very soon. QUDO token price will 
                            be determined by market dynamics when launched in the main net.
                        </div>
                        <div className="mb-3">
                            Following the main net launch, it is planned for QUDO to be listed on crypto exchanges later this 
                            year (check
                            
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://qudo.io/team-and-project#roadmap"
                                className="d-inline-block mx-1"
                            >
                                QUDO’s roadmap
                            </a>
                            
                            ), allowing you to trade QUDO tokens. This, however, will not happen immediately.
                        </div>
                        <div>
                            We strongly suggest you join our

                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://discord.gg/SwmdFkMhhe"
                                className="d-inline-block mx-1"
                            >
                                Discord
                            </a>

                            and

                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://t.me/qudocoin"
                                className="d-inline-block mx-1"
                            >
                                Telegram
                            </a>

                            channels, to receive all updates in first hand.
                        </div>
                    </div>  
                </div>
            </Modal>
            </>
        );
    }

    return(
        <div className="w-100">
            <button id="qudoinfobtn" onClick={() => setButton('howToBuy')}>
                HOW TO <b>GET</b> QUDO?
                <img style={{float: "right"}} src={arrow} alt="arrow"/>
            </button>
            <button id="qudoinfobtn" onClick={() => setButton('howToSell')}>
                HOW TO <b>EXCHANGE</b> QUDO?
                <img style={{float: "right"}} src={arrow} alt="arrow"/>
            </button>

            {modals()}
        </div>
    );
}

// This component is DEPRECATED, used in the old Wallet design. It's just here for reference
export default function UserBalances() {
	const balances = useSelector(state => state.balance);
	const currentUser = useSelector(state => state.info);

    const [history, setHistory] = useState([]);

    const [balance, setBalance] = useState(0);
    const [savings, setSavings] = useState(0);
    const [stake, setStake] = useState(0);

    // const [lastMonthBalance, setLastMonthBalance] = useState(0);
    // const [lastMonthSavings, setLastMonthSavings] = useState(0);

    const [chartLoaded, setChartLoaded] = useState(false);

    useEffect(() => {
        getBalance();
        getBalanceHistory();
    }, []);

    useEffect(() => {
        getBalance();
        //getBalanceHistory();
    }, [balances]);

    useEffect(() => {
        renderChart();
    }, [history]);

    function getBalance() {
        if(balances) {
            if(balances.balance) {
                const hasStake = balances.stake ?
                    Number(balances.stake.split(' ')[0]) : 
                    0;
                setBalance(Number(balances.balance.split(' ')[0]) - hasStake);
            }

            if(balances.savings) {
                setSavings(Number(balances.savings.split(' ')[0]));
            }

            if(balances.stake) {
                setStake(Number(balances.stake.split(' ')[0]));
            }
        }
    }

    function getBalanceHistory() {
        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/api/history/balance`, {username: currentUser.username})
        .then(async (result) => {
            if(result && result.data) {
                setHistory(result.data.balances);

                // const lastMonthSnapshot = await result.data.balances.find(element => {
                //     return element.savings && new Date(element.time).getTime() > Date.now() - (30 * 24 * 60 * 60 * 1000);
                // });

                // if(lastMonthSnapshot) {
                //     setLastMonthBalance(Number(lastMonthSnapshot.balance.split(' ')[0]));
                //     setLastMonthSavings(Number(lastMonthSnapshot.savings.split(' ')[0]));
                // }
            }
        });
    }

    function renderChart() {
        const dates = [];
        const values = [];

        if(Array.isArray(history)) {
            for(var i = 0; i < history.length; i++) {
                const snapshot = history[i];

                values.push(Number(snapshot.balance.split(' ')[0]));
                dates.push(Moment(snapshot.time).tz(Moment.tz.guess()).format('DD MMM @ hh:mm A'));
            }
        }

        if(values.length > 0 && dates.length > 0) {
            setChartLoaded(true);
        } else {
            setChartLoaded(false);
            return null;
        }

		return Highcharts.chart("chart", {
			chart: {
				type: "area",
				height: "300rem",
				backgroundColor: "transparent"
			},
			title: {
				text: ""
			},
			subtitle: {
				align: "right",
				verticalAlign: "bottom"
			},
			colors: [
				"#000000",
				"#000000",
				"#000000",
				"#000000",
				"#000000",
				"#000000",
				"#000000",
				"#000000",
				"#000000",
				"#000000"
			],
			legend: {
				enabled: false
			},
			xAxis: {
				categories: [...dates]
			},
			yAxis: {
				title: {
					text: ""
				},
                max: values.length > 0 ? Math.round(Math.max(Math.max(...values)+1000, Math.max(...values)*1.05)) : 0,
                min: values.length > 0 ? Math.round(Math.max(Math.min(Math.min(...values)-1000, Math.min(...values)*0.9), 0)) : 0,
				gridLineColor: "transparent"
			},
			plotOptions: {
				area: {
					fillOpacity: 0.5,
					fillColor: {
						linearGradient: [0, 0, 0, 300],
						stops: [
							[0, "#dedede"],
							[1, "#ffffff"]
						]
					}
				}
			},
			credits: {
				enabled: false
			},
			series: [
				{
					name: "balance",
					data: [...values],
					marker: {
						fillColor: "#fbd731"
					}
				}
			]
		});
    }

    return(
        <div className="w-100">
            <div className="column mb-4">
                <div className="d-flex flex-column w-100 pt-2">
                    <div className="justify-content-start mt-auto">
                        Available Balance
                    </div>
                    <div id="balancetext">
                        {balance.toFixed(4).split('.').map(balanceNumbers)}
                    </div>
                </div>
                <div className="d-flex flex-column w-100 pt-2">
                    <div className="justify-content-end mt-auto">
                        Pocket Change
                    </div>
                    <div id="balancetext">
                        {savings.toFixed(4).split('.').map(balanceNumbers)}
                    </div>
                </div>
                <div className="d-flex flex-column w-100 pt-2">
                    <div className="justify-content-end mt-auto">
                        Stake
                    </div>
                    <div id="balancetext">
                        {stake.toFixed(4).split('.').map(balanceNumbers)}
                    </div>
                </div>
            </div>

            {/*}
            <div className="row mb-2">
                <div className="col-6">
                    <div className="justify-content-start">
                        Last Month
                    </div>
                    <div
                        id="balancetext"
                        style={
                            Number(balance - lastMonthBalance) !== 0 ? (
                                Number(balance - lastMonthBalance) > 0 ? {color: 'green'} : {color: 'red'}
                            ) : (
                                {color: 'grey'}
                            )
                        }
                    >
                        {Number(balance - lastMonthBalance) >= 0 ? '+' : ''}{Number(balance - lastMonthBalance).toFixed(4)}
                    </div>
                </div>
                <div className="col-6">
                    <div className="justify-content-end">
                        Last Month
                    </div>
                    <div
                        id="balancetext"
                        style={
                            Number(savings - lastMonthSavings) !== 0 ? (
                                Number(savings - lastMonthSavings) > 0 ? {color: 'green'} : {color: 'red'}
                            ) : (
                                {color: 'grey'}
                            )
                        }
                    >
                        {Number(savings - lastMonthSavings) >= 0 ? '+' : ''}{Number(savings - lastMonthSavings).toFixed(4)}
                    </div>
                </div>
            </div>
            {*/}

            <div className="row mt-4">
                <div className="col-12">
                    <div id="chart"/>
                </div>
            </div>

            {chartLoaded && (
				<div className="row mx-0 mb-2" style={{fontSize: '0.9rem'}}>
					<div className="col-12">
                        Since we use data from the blockchain, which usually has some delay relative to the moment 
                        that the actions are made, the chart may not show the updated values ​​from the last minute.
				    </div>
			    </div>
			)}

            {currentUser && currentUser.qudoManaged && (
                <div className="row mt-3 mb-2">
                    <div className="col-12">
                        <WalletMigrationCard/>
                    </div>
                </div>
            )}

            <div className="row">
                <div className="col-12">
                    <InfoButtons/>
                </div>
            </div>
        </div>
    );
}
