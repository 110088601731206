import React, { useEffect } from 'react';
import cssColors from 'helpers/cssColors.js';
import useWindowSize from 'helpers/useWindowSize';


export default function WalletTotalBalance({
    Balance,                // (mandatory) - undefined for "Loading", null for "Bad Read", or it as number 
    Stake,                  // (optional)  - either 0 or number
    detailColor,            // (optional)  - used for some decoration
}) {
    const windowSize = useWindowSize();
    const isMobile = (windowSize && windowSize.width < 992);

    let balance = Balance;
    let stake = Stake;

    if( Balance !== undefined ){
        try { balance = parseFloat(Balance); }
        catch(e) { balance = 0; }
        balance = balance.toFixed(4);
    } else if( Balance === undefined ){
        balance = 'Loading'
    } else if( Balance === null ) {
        balance = 'Failed to read'
    }

    if( Stake !== undefined ){
        try { stake = parseFloat(Stake); }
        catch(e) { stake = 0; }
        stake = stake.toFixed(4);
    }

    return (<div style={{display: "flex", flexDirection: "column", gap: "32px"}}> 

{ /*balance!==undefined && */
    <div className={"wallet-balance-container"}>
        <div className={"wallet-balance-title wallet-balance-border"} style={{borderColor:`${detailColor?detailColor:''}`}} >
            Total Balance
        </div>
        <div className={"wallet-balance-balance wallet-balance-border"} style={{borderColor:`${detailColor?detailColor:''}`}} >
            <div style={{display: "flex", alignItems: "flex-end", fontWeight: "lighter"}}>
                <div style={{fontSize: `${isMobile?"40px":"54px"}` }}>
                    {balance.split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                </div>

                {typeof balance === 'string' &&
                    <div style={{fontSize: `${isMobile?"24px":"40px"}` }}>
                        ,{balance.split(".")[1]}
                    </div>
                }
            </div>
            {typeof balance === 'string' &&  
                <div style={{fontSize: `${isMobile?"14px":"16px"}`}}>
                    QUDO
                </div>
            }
        </div>
    </div>
}

{stake!==undefined && 
    <div className={"wallet-balance-container"}>
        <div className={"wallet-balance-title wallet-balance-border"} style={{borderColor: cssColors.QUDO_MEDIUM_GREY}}>
            Stake
        </div>
        <div className={"wallet-balance-balance wallet-balance-border"} style={{borderColor: cssColors.QUDO_MEDIUM_GREY}}>
            <div style={{display: "flex", alignItems: "flex-end", fontWeight: "lighter"}}>
                <div style={{fontSize: "40px"}}>
                    {stake.split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                </div>
                <div style={{fontSize: "24px"}}>
                    ,{stake.split(".")[1]}
                </div>
            </div>
            <div style={{fontSize: "16px"}}>
                QUDO
            </div>
        </div>
    </div>
}

</div>);
}