import React, {useState, useEffect} from 'react';

import { OverlayTrigger, Tooltip } from "react-bootstrap";

import {useDispatch, useSelector} from 'react-redux';
import {arrayToHex} from "eosjs/dist/eosjs-serialize";
//import {Modal, Form} from 'react-bootstrap';
import {ClipLoader} from 'react-spinners';

import * as actions from '../../../actions';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import {MessageModal} from '../../components/modals/modals.js';
import WalletImportTutorial from '../walletMigration/walletImportTutorial';

import QUDOServer from '../../../helpers/QUDOServerConnection';

import * as MK from '../../../helpers/metakeep';

const send = require('../../../images/definitive/icn-send-qudos.png');
//const arrow = require('../../../images/definitive/icn-arrow-forward.png');

function UnstakeErrorMessage(e){
    if(e.errorMessage !== 'buildMessage')
        return e.errorMessage
    console.log(e.data);
    const {currentStake,lockedStake,stakeMinDays,quantity} = e.data;
    return(
        <div>
<span><b>Can't unstake {quantity} QUDO</b></span>
<br/>
<span>Some of your current stake is still locked because it was staked recently. </span>
<br/>
<br/>
<span><b>Current stake:</b> {currentStake} QUDO</span>
<br/>
<span style={{ textDecoration: 'line-through' }} ><b>Locked stake:</b> {lockedStake} QUDO </span>
<br/>
<br/>
<span><span role="img" aria-label="warning sign">⚠️</span> Note: Stake has a minimum lock period of {stakeMinDays} days. </span>
<br/>
<span>Once this period ends, you'll be able to unstake your QUDO tokens</span>
        </div>
    )
}

export default function StakesComponent({
    detailColor,        // color to highlight certain components
}) {
	const dispatch = useDispatch();

	const ual = useSelector(state => state.ual);
	const info = useSelector(state => state.info);
	const balance = useSelector(state => state.balance);

    const [staking, setStaking] = useState(false);
    //const [validStake, setValidStake] = useState(false);

    const [newStake, setNewStake] = useState(null);
    const [minimumStake, setMinimumStake] = useState(null);

	const [modalMessage, setModalMessage] = useState('');
    const [stakeLoading, setStakeLoading] = useState(false);
    //const [confirmStakingModal, setConfirmStakingModal] = useState(false);

	const [tutorialModal, setTutorialModal] = useState(false);

    useEffect(() => {
        resetStake();
    }, []);

    useEffect(() => {
        if(!staking) {
            resetStake();
        }
    }, [balance]);

    async function saveStake(e) {
        //e.preventDefault();

        setStakeLoading(true);

        var currentStake = String(balance.stake).split(' ')[0];
        currentStake = (!isNaN(currentStake) ? Number(currentStake) : 0);

        var endpoint = '';
        var actionName = '';
        var value = Number(Number(newStake - currentStake).toFixed(4));

        if(value > 0) {
            actionName = 'stake';
            endpoint = '/eos/stake';
        } else if(value < 0) {
            value = value * -1;
            actionName = 'unstake';
            endpoint = '/eos/unstake';
        } else {
            setModalMessage("Can't stake/unstake the same amount");
            //setConfirmStakingModal(false);
            setStakeLoading(false);
            return;
        }
        
        if(info.qudoManaged){
            if(process.env.REACT_APP_METAKEEP_RELEASED === 'true' || info.email === global.testAccEmail){
                const account = process.env.REACT_APP_TOKENCONTRACT;
				const actions = [{
                    account: account,
                    name: actionName,
                    authorization: [{
                        actor: info.account_name,
                        permission: "active"
                    }],
                    data: {
                        account: info.account_name,
                        amount: `${Number(value).toFixed(4)} ${process.env.REACT_APP_TOKENNAME}`
                    }
                }];

				MK.signTransaction(info.email, actions, {broadcast: false})
				.then((signResponse) => {
                    QUDOServer.post(process.env.REACT_APP_QUDO_SERVER + endpoint, {
                        quantity: value,
                        signature: signResponse.unpackedTransaction,
                    }, {
                        withCredentials: true
                    })
                    .then(result => {
                        setStaking(false);
                        setModalMessage("Success");
                        dispatch(actions.updateBalance());
                    })
                    .catch(error => {
                        console.log("Caught an error -> ");
                        console.log(error);
                        let message = actionName==='unstake'?UnstakeErrorMessage(error):error.errorMessage
                        setModalMessage(message)
                    })
                    .finally(() => {
                        setStakeLoading(false);
                    });
					//console.log("sign successful", signResponse);
                    //setStaking(false);
                    //setModalMessage("Success");
                    //dispatch(actions.updateBalance());
				})
				.catch((error) => {
					console.log("Metakeep send error on account", MK.getEOS(), error);
                    console.log(error);
                    setModalMessage(error.errorMessage);
				})
				.finally(() => {
                    setStakeLoading(false);
				});
            }
            else
            { // Delete later after Metakeep is integrated
                QUDOServer.post(process.env.REACT_APP_QUDO_SERVER + endpoint, {
                    quantity: value
                }, {
                    withCredentials: true
                })
                .then(result => {
                    setStaking(false);
                    setModalMessage("Success");
                    dispatch(actions.updateBalance());
                })
                .catch(error => {
                    console.log("Caught an error -> ");
                    console.log(error);
                    let message = actionName==='unstake'?UnstakeErrorMessage(error):error.errorMessage
                    setModalMessage(message)
                })
                .finally(() => {
                    //setConfirmStakingModal(false);
                    setStakeLoading(false);
                });
            }
        }

        else

        {
            let signatureData = null;
            try {
                if(!ual.activeUser) {
					ual.logout();
					ual.restart();
					ual.showModal();
                    setStakeLoading(false);
                    return;
                } else {
                    const signedData = await ual.activeUser.signTransaction({
                        actions: [{
                            account: process.env.REACT_APP_TOKENCONTRACT,
                            name: actionName,
                            authorization: [{
                                actor: info.account_name,
                                permission: "active"
                            }],
                            data: {
                                account: info.account_name,
                                amount: `${Number(value).toFixed(4)} ${process.env.REACT_APP_TOKENNAME}`
                            }
                        }]
                    }, {
                        blocksBehind: 3,
                        expireSeconds: 30,
                        broadcast: false
                    });

                    signatureData = {
                        signatures: signedData.transaction.signatures,
                        packed_trx: arrayToHex(signedData.transaction.serializedTransaction)
                    }
                }
            } catch(error) {
                console.log("Error signing transaction -> ");
                console.log(error);
                setModalMessage(error.toString());
                //setConfirmStakingModal(false);
                setStakeLoading(false);
                return;
			}

            QUDOServer.post(process.env.REACT_APP_QUDO_SERVER + endpoint, {
                quantity: value,
                signature: signatureData
            }, {
                withCredentials: true
            })
            .then(result => {
                setStaking(false);
                setModalMessage("Success");
                dispatch(actions.updateBalance());
            })
            .catch(error => {
                console.log("Caught an error -> ");
                console.log(error);
                let message = actionName==='unstake'?UnstakeErrorMessage(error):error.errorMessage
                setModalMessage(message)
            })
            .finally(() => {
                //setConfirmStakingModal(false);
                setStakeLoading(false);
            });
        }
    }

    function resetStake() {
        setStaking(false);

        if(balance) {
            if(balance.stake) {
                const currentStake = balance.stake.split(' ')[0];
                setNewStake(Number(currentStake));
            } else {
                setNewStake(0);
            }

            if(balance.minimumStake) {
                let minStake = Number(balance.minimumStake.split(' ')[0]);
                setMinimumStake(minStake);
            }
        }
    }

    function changeStake(value) {
        const minLimit = 0;
        const maxLimit = balance && balance.balance ? Number(balance.balance.split(' ')[0]) : 0;

        if(!value || isNaN(value)) {
            value = parseFloat(String(value).replace(',', '.'));
        }

        if(!value || isNaN(value)) {
            value = parseFloat(String(value).replace('.', ','));
        }

        value = parseFloat(value);

        if(isNaN(value)) {
            value = Number(balance && balance.stake ? balance.stake.split(' ')[0] : 0);
		} else {
			value = value > maxLimit ? maxLimit : value;
			value = value < minLimit ? minLimit : value;
		}

        /*
        if(Number(value.toFixed(4)) === Number(balance.stake.split(' ')[0])) {
            setValidStake(false);
        } else {
            setValidStake(true);
        }
        */

        setNewStake(Number(value.toFixed(4)));
    }

    /*
    function ConfirmModal() {
        return(
            <Modal show={confirmStakingModal} onHide={() => setConfirmStakingModal(!confirmStakingModal)}>
                <Modal.Body className="confirm-modal">
                    <Form onSubmit={(e) => saveStake(e)} style={{ padding: "2rem 4rem 0rem" }}>
                        <div className="row mb-5 confirm-modal-text">
                            <div className="col-12 text-center">Confirm?</div>
                        </div>
                        <div className="row mb-2 justify-content-between">
                            <div className="col-12">
                                <div className="stake-yellow text-center">
                                    {newStake}
                                </div>
                                <div className="text-center">
                                        Your new stake
                                </div>
                            </div>
                        </div>
                        <div className="row d-flex position-relative" style={{ margin: "1rem -4rem 0rem" }}>
                            <input
                                value="cancel"
                                className={`text-center col-6 confirm-modal-button cursor-pointer`}
                                onClick={() => setConfirmStakingModal(!confirmStakingModal)}
                            />
                            <input
                                value="confirm"
                                type="submit"
                                className={`col-6 confirm-modal-button`}
                            />
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
    */
   const config = useSelector(state => state.config);

    const miniumTimeToStakeTooltip = (
        <Tooltip placement="right" className="">
            <span className="text-light"><small>
                {`The amount staked is locked for ${config.stake_lock_time_days} day${config.stake_lock_time_days > 1 ? 's' : ''}`}
            </small></span>
        </Tooltip>
    );


    return(
        balance && balance.stake ? (
            <>
            <div className="card roundcard shadow" style={{ borderColor:`${detailColor?detailColor:''}` }} >
                <div className="card-body p-4">
                    <div className="row mb-3">
                        <div className="col-1">
                            <img src={send} className="mr-2" alt="send"/>
                        </div>
                        <div className="col-10">
                            Stake
                            <OverlayTrigger placement="right" overlay={miniumTimeToStakeTooltip}>
                                <FontAwesomeIcon icon={faInfoCircle} className="ml-1" />
                            </OverlayTrigger>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className={
                            // info && (
                            //     info.type === process.env.REACT_APP_GAMEDEV_TYPE
                            //     || info.type === process.env.REACT_APP_PROVIDER_TYPE
                            //     || info.type === process.env.REACT_APP_GUILD_TYPE
                            // )
                            // ? "col-6" : "col-12"
                            "col-6"
                        }>
                            <input
                                type="number"
                                className="edit-screen-stakes-big-input special-input text-center"
                                style={
                                    newStake && newStake !== Number(balance.stake.split(' ')[0])
                                    ? (newStake > Number(balance.stake.split(' ')[0]) ? {color: "#00cc69"} : {color: "#f2544c"})
                                    : ({})
                                }
                                step={0.01}
                                disabled={!staking}
                                value={!isNaN(newStake) ? Number(newStake) : Number(balance.stake.split(' ')[0])}
                                onChange={(e) => setNewStake(e.target.value)}
                                onBlur={() => changeStake(!isNaN(newStake) ? newStake : Number(balance.stake.split(' ')[0]))}
                            />

                            {newStake && newStake !== Number(balance.stake.split(' ')[0]) ? (
                                <div className="edit-screen-stakes-sm text-center">
                                    New Stake
                                </div>
                            ) : (
                                <div className="edit-screen-stakes-sm text-center">
                                    Current Stake
                                </div>
                            )}
                        </div>

                        {
                            // info && (
                            //     info.type === process.env.REACT_APP_GAMEDEV_TYPE
                            //     || info.type === process.env.REACT_APP_PROVIDER_TYPE
                            //     || info.type === process.env.REACT_APP_GUILD_TYPE
                            // ) ? (
                                <div className="col-6">
                                    <input
                                        className="edit-screen-stakes-big-input text-center"
                                        disabled
                                        value={!isNaN(minimumStake) ? minimumStake : '???'}
                                    />

                                    <div className="edit-screen-stakes-sm text-center">
                                        Minimum stake
                                        {/*}
                                        {info && info.type === process.env.REACT_APP_GAMEDEV_TYPE && ' to add games'}
                                        {info && info.type === process.env.REACT_APP_PROVIDER_TYPE && ' to add services'}
                                        {info && info.type === process.env.REACT_APP_GUILD_TYPE && ' to add new members'}
                                        {*/}
                                    </div>
                                    {/*}
                                    <div className="edit-screen-stakes-faded text-center">
                                        at time of registration
                                    </div>
                                    {*/}
                                </div>
                            // ) : (
                            //     <></>
                            // )
                        }
                    </div>

                    {!info.qudoManaged && !ual.activeUser ? (
                        <>
                            <div className="px-1 pb-3">
                                Please sign in with one of the available wallets to change your QUDO stake
                            </div>
                            <button
                                type="button"
                                className="std-button-active"
                                onClick={() => {
                                    ual.logout();
                                    ual.restart();
                                    ual.showModal();
                                }}
                            >
                                <div className="text-center">
                                    SIGN IN
                                </div>
                            </button>
							<button
							    type="button"
								className="std-button mb-0"
								style={{backgroundColor: '#00cc69'}}
								onClick={() => setTutorialModal(!tutorialModal)}
							>
								<div className="text-center">
									HOW TO SIGN IN
								</div>
							</button>

                            {tutorialModal && (
                                <WalletImportTutorial
                                    show={tutorialModal}
                                    hide={() => setTutorialModal(!tutorialModal)}
                                />
                            )}
                        </>
                    ) : (
                        <>
                        {stakeLoading ? (
                            <div className="row">
                                <div className="col-12">
                                    <div className="text-center">
                                        <ClipLoader color="#282725"/>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                            {staking ? (
                                <div className="row">
                                    <div className="col-12">
                                        <input
                                            required
                                            type="range"
                                            style={{clear: "both"}}
                                            className="custom-range"
                                            disabled={!staking}
                                            min={0}
                                            max={balance && balance.balance ? Number(balance.balance.split(' ')[0]) : 0}
                                            value={!isNaN(newStake) ? Number(newStake) : Number(balance.stake.split(' ')[0])}
                                            onChange={(e) => changeStake(e.target.value)}
                                            step="0.0001"
                                        />
                                    </div>
                                    <div className="row col-12 pl-0 pr-0 mt-3 mr-0 ml-0">
                                        <div
                                            className="col-6 button text-center red py-3 cursor-pointer"
                                            onClick={() => resetStake()}
                                        >
                                            CANCEL
                                        </div>
                                        <div
                                            className="col-6 button text-center green py-3 cursor-pointer"
                                            //onClick={() => setConfirmStakingModal(true)}
                                            onClick={() => saveStake()}
                                        >
                                            SAVE
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <button
                                    type="button"
                                    className="std-button-active"
                                    onClick={() => setStaking(true)}
                                >
                                    <div className="text-center">
                                        CHANGE STAKE
                                    </div>
                                </button>
                            )}
                            </>
                        )}
                        </>
                    )}
                </div>
            </div>

            {/* <ConfirmModal/> */}

			<MessageModal
			    show={modalMessage}
				message={modalMessage}
				hide={() => setModalMessage('')}
			/>
            </>
        ) : (
            <></>
        )
    );
}
