import React, { useEffect } from "react";
import { DropdownInput } from '../../forms/dropdowns';
import { NameInput } from "../../forms/text";
import { Form } from "react-bootstrap";



export function GameDetailsAchievementFilters({
    game,
    achievements,
    statistics, // {totalGamers:number, achievementCompletions:{ [alias:number] } }
    playerStats, // {  [game] : [{alias, fee, reward, lastEarned, repeated?}]  } // If (undefined / null) it will be as not logged in
    fields, // sortGet, sortSet, filterGet, filterSet, searchGet, searchSet
}) {
    
    if(!achievements || !game) return (<></>);

    const playerAchievements = playerStats ? playerStats[game] : {};
    const completedAchievements = playerAchievements.length || 0;
    const totalAchievements = achievements.length;
    const completedPercentage = totalAchievements ? (completedAchievements / totalAchievements * 100).toFixed(1) : 0;

    if(totalAchievements === 0) return (<></>);

    // All/Completed/Uncompleted/
    // Sort by default/CompletedDate^/Name^/Points^ ( bigger Recent on top)
    // Title search
    return (

<div className="achievement-filter-container" key={`${game + '-achievement-filter'}`}>

    <div className="achievement-filter-element achievement-filter-unlocked ">
        <div style={{fontSize:'1.1vw', fontWeight:'200', letterSpacing:'-1px !important'}}>YOU'VE UNLOCKED</div>
        <div style={{fontWeight:'300'}}>
            <span style={{fontSize:'0.9vw'}}>{`${completedAchievements} / `}</span>
            <span style={{fontSize:'1vw', fontWeight:'500'}}>{totalAchievements}</span>
            <span style={{fontSize:'0.95vw'}}>{` (${completedPercentage}%) `}</span>
        </div>
    </div>

    <div className="achievement-filter-element">
        <DropdownInput
            label='Filter'
            initial='All'
            set={fields.filterSet}
            choices={[
                { key: 'all', label: 'All' },
                { key: 'completed', label: 'Completed' },
                { key: 'uncompleted', label: 'Uncompleted' },
            ]}
            style={{ width: "92.5%", height: "64px !important", marginBottom: "0px" }}
        />
    </div>

    <div className="achievement-filter-element">
        <DropdownInput
            label='Sort'
            initial='Default'
            set={fields.sortSet}
            choices={[
                { key: 'default', label: 'Default' },
                { key: 'name', label: 'Name' },
                { key: 'reward', label: 'Reward' },
                { key: 'completed', label: 'Completed Date' },
                { key: 'rarest', label: 'Rarest' },
            ]}
            style={{ width: "92.5%", height: "64px !important", marginBottom: "0px" }}
        />
    </div>

    <div className="achievement-filter-element">
        <Form>
            <NameInput
                title=''
                placeholder="Search"
                initial={fields.searchGet}
                value={fields.searchGet}
                set={fields.searchSet}
                ignoreValidation={true}
                disabled={false}
                style={{ width: "92.5%", height: "64px !important", marginBottom: "0px" }}
            />
        </Form>	
    </div>
</div>

    )
}