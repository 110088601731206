import React, { useState } from 'react';
import { ethers } from 'ethers';
import { shortenAddress } from 'helpers/EthereumContext';
import { copyToClipboard } from 'helpers/clipboard';
import cssColors from 'helpers/cssColors.js';
import copyIcon from 'images/icon-copy.svg';

//     username     or     address     //
export default function AddressUsernameDisplay ({ name }) {
    const [copyClicked, setCopyClicked] = useState(false);

    const isMobile = ( window && window.innerWidth < 768);

    if( name === null || name === undefined ){
        name = "";
    } else 
    if ( typeof(name) !== "string" ){
        name = String(name);
    }

    const isAddress = ethers.utils.isAddress(name) // || name.startsWith("0x") || name.length > 13; // eosio max lenght is 12

    const handleCopyClick = () => {
        copyToClipboard(name);
        setCopyClicked(true);
        setTimeout(() => setCopyClicked(false), 200); // Reset the animation class after the animation duration
    };

    return (<div>
        <div style={{marginBottom: "8px", lineHeight: "19.2px",
            fontSize: "13px", color:cssColors.QUDO_DARK_GREY, fontWeight: "600"
        }}>
            {isAddress ? "Address" : "Username"}
        </div>
        <div style={{ padding: "11px 8px", 
            display: "flex", alignItems: "center", justifyContent: "space-between",
            backgroundColor: cssColors.WHITE, width: "100%", borderRadius: "8px",
        }}>
            <div className='wallet-username-text'>
                { isAddress && isMobile 
                ? shortenAddress(name, 8)
                : name
                }
            </div>
            <div style={{marginLeft: "4px",}}>
                <img src={copyIcon} alt="Copy"
                    className={`copy-icon ${copyClicked ? 'click-animation' : ''}`}
                    style={{width: "22px", height: "30px", cursor: "pointer"}}
                    onClick={handleCopyClick}
                />
            </div>
        </div>
    </div>)
}