import React, { useState, useEffect }   from 'react'
import { useDispatch, useSelector }     from 'react-redux'
import * as actions                     from 'actions'
import { getFloat, rgbaToHex }          from 'helpers/utils'
import { useEthereum, chains }          from 'helpers/EthereumContext.js'

import Loading                          from 'components/common/loading'
import WalletTitle                      from 'components/walletMultichain/Title'
import WalletLandingPage                from 'components/walletMultichain/screens/LandingPage'
import WalletZeroPage                   from 'components/walletMultichain/screens/ZeroPage'
import WalletTEVMPage                   from 'components/walletMultichain/screens/TEVMPage'
import WalletBasePage                   from 'components/walletMultichain/screens/BasePage'


export const pages = {
    LANDING:    {id:-1,                         title: "Wallet",        returnsTo: undefined},
    ZERO:       {id:0,                          title: "Telos Zero",    returnsTo: -1},
    TEVM:       {id:chains.telosEVM.chainId,    title: "Telos EVM",     returnsTo: -1},
    BASE:       {id:chains.base.chainId,        title: "Telos Base",    returnsTo: -1},
    pageById(id){ return Object.values(this).find(p=>p.id===Number(id)); },
}


export default function MultichainWallet() {
	const dispatch = useDispatch();
    const eth = useEthereum();

    const isMobile = ( window && window.innerWidth < 768);

    const [loading, setLoading] = useState(true);
    const walletPage = useSelector((state) => state.chosenWalletPage );

    const currentUser = useSelector(state => state.info);

    const balances              = useSelector(state => state.balance);
    const [balance, setBalance] = useState(0);  // telos zero QUDO balance
    const [savings, setSavings] = useState(0);  // pocket change
    const [stake, setStake]     = useState(0);  // telos zero QUDO staked
    const [evmBalance, setEvmBalance]   = useState(0);
    const [baseBalance, setBaseBalance] = useState(0);
    const [totalBalance, setTotalBalance] = useState(0);

    function calculateBalance() {
        if(balances) {
            if(balances.balance) 
                setBalance(Number(balances.balance.split(' ')[0]));
            if(balances.savings) 
                setSavings(Number(balances.savings.split(' ')[0]));
            if(balances.stake) 
                setStake(Number(balances.stake.split(' ')[0]));
        }
    }

    useEffect(() => {

        calculateBalance();
		dispatch(actions.updateBalance());
        //dispatch(actions.setWalletPage(pages.LANDING.id));
        //eth.updateBalances();
        // eth auto does this, since it switches chains, force only if you have a reason
        setLoading(false);
        return () => {
            console.log("Wallet unmounted");
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        calculateBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [balances]);

    useEffect(()=>{
        setTotalBalance(balance + savings + evmBalance + baseBalance)
    }, [balance, savings, evmBalance, baseBalance]);

    useEffect(()=>{
        setEvmBalance(getFloat(eth.balances[chains.telosEVM.chainId],0 ));
        setBaseBalance(getFloat(eth.balances[chains.base.chainId], 0));   
    }, [eth.balances])

    if(loading) {
        return <Loading/>;
    }

    const pageSelector = (pageId) => {
        switch(pageId) {
            case pages.LANDING.id:
                return <WalletLandingPage />;
            case pages.ZERO.id:
                return <WalletZeroPage />;
            case pages.TEVM.id:
                return <WalletTEVMPage />;
            case pages.BASE.id:
                return <WalletBasePage />;
            default:
                return <WalletLandingPage />;
        }
    }

    return (
<div className="row">
    <div id="wallet-header" className="col-12 text-left pl-5" 
        style={{ backgroundColor:'#212529', color:'white', fontWeight:'bold' }}
    >
        {'The QUDO Wallet 2.0 is multichain ready, but is still in Beta. if you found any problem please '}
        <a href="https://qudo.io/contacts#contact" rel="noopener noreferrer" target="_blank"
            style={{ color:rgbaToHex('rgb(137 193 253)') }}
        >
            <u>contact us</u>
        </a>
    </div>
    <WalletTitle
        switchPage={pages.pageById(walletPage).returnsTo!==undefined? ()=>dispatch(actions.setWalletPage( pages.pageById(walletPage).returnsTo )) :null}
        title={pages.pageById(walletPage).title}
    />
    {pageSelector(walletPage)}
</div>
    );
}
