import { SET_WALLET_PAGE } from 'actions/types';

import { pages } from 'components/WalletMultichain';

const initialState = pages.LANDING.id;

export default function(state = initialState, action) {
	switch (action.type) {
		case SET_WALLET_PAGE:
			console.log(`Reducer ${SET_WALLET_PAGE} received payload`, action.payload)
			return action.payload
		default:
			return state;
	}
}
