import { decodeJWT } from "./decoder.js";

//MARK: Listeners
export function addEtherMailSignInListener(callback = defaultEtherMailSignInOnSuccess) {
    if(global.isDev){console.log("[EtherMail] Adding EtherMail sign-in listener");}
    window.addEventListener("EtherMailSignInOnSuccess", async (event) => {
        await callback(event);
    });
}

export function removeEtherMailSignInListener(callback = defaultEtherMailSignInOnSuccess) {
    if(global.isDev){console.log("[EtherMail] Removing EtherMail sign-in listener");}
    window.removeEventListener("EtherMailSignInOnSuccess", callback);
}

async function defaultEtherMailSignInOnSuccess(event) {
    console.log("EtherMail sign-in was successful");
    ethermail_logEvent(event);
}

//MARK: Helpers/logs

export function ethermail_eventToToken(event){
    const detail = event.detail;
    if (!detail){if(global.isDev){console.error("[EtherMail] No detail in event")};return null;}

    const token = detail.token;
    if (!token){if(global.isDev){console.error("[EtherMail] No token in detail")};return null;}

    return token;
}

export function ethermail_logEvent(event){
    console.log("[EtherMail] event:", event);
    if (!event) {console.error("[EtherMail] No event");return;}
    ethermail_logDetails(event.detail);
    console.log("[EtherMail] event --- end");
}

export function ethermail_logDetails(detail){
    console.log("[EtherMail] detail: ", detail);
    if (!detail) {console.error("[EtherMail] No detail in event");return;}
    ethermail_logToken(detail.token);
    console.log("[EtherMail] detail --- end");
}

export function ethermail_logToken(token){
    console.log("[EtherMail] (JWT) token: ", token);
    if (!token) {console.error("[EtherMail] No token in detail");return;}
    if (typeof token === "string")
        ethermail_logDecodedToken(decodeEtherMailToken(token));
    // token should be JWT
    console.log("[EtherMail] (JWT) token --- end");
}


export function ethermail_logDecodedToken(decodedToken){
    console.log("[EtherMail] (decoded) token: ", decodedToken);
    if (!decodedToken) {console.error("[EtherMail] Couldn't decode the token");return;} 
    ethermail_logPayload(decodedToken.payload);
    console.log("[EtherMail] (decoded) token --- end");
}

export function ethermail_logPayload(payload){
    console.log("[EtherMail] payload: ", payload);
    if (!payload) {console.error("[EtherMail] bad payload");return;} 

    const isValidEmail = payload.ethermail_verified;
    const email = payload.address;

    if(!isValidEmail) {console.warn(`[EtherMail] email <${email}> is not valid <${isValidEmail}> `);return;}
    console.log(`[EtherMail] Email <${email}> is valid`);
}


//MARK: Decoders
export function decodeEtherMailToken(token){
    return decodeJWT(token);
}
