
export function eligbleForWalletMultichainBeta(userInfo){
    if( !userInfo ) return false
    if( typeof userInfo !== "object" ) return false

    const email = String(userInfo.email);
    if( email.endsWith('@blockbastards.io') )
        return true

    return false
}

export function eligleForMainnetMigration(userInfo){
    if( !userInfo ) 
        return false
    if( userInfo.migratedMainnet )
        return false
    if( process.env.REACT_APP_TELOS_ENVIRONMENT === 'mainnet' )
        return true
    
    const email = String(userInfo.email);
    if( email.endsWith('@blockbastards.io') )
        return true

    return false
}