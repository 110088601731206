import React, { useEffect, useState } 	from "react";
import { useSelector, useDispatch } 	from "react-redux";
import { Route, Switch, Redirect } 		from 'react-router';
import { ConnectedRouter } 				from 'connected-react-router';

import * as actions 	from "actions";
import { eligbleForWalletMultichainBeta } 	from 'helpers/betasEliglebles'

import Layout 	from 'layouts/layout'
//import withClearCache from "./ClearCache";
import Community 				from "components/Community"
import Wallet 					from "components/Wallet"
import MultichainWallet 		from "components/WalletMultichain"
import EditProfile 				from "components/EditProfile"
import UserActivity 			from "components/UserActivity"
import GamedevActivity 			from "components/GamedevActivity"
import GamedevMarketplace 		from 'components/GamedevMarketplace'
import NewGame 					from "components/gamedev/gameManagement/newGameComponent/newGame"
import NewApplication 			from "components/gamedev/applicationManagement/newApplicationComponent/newApplication"
import GameEditScreen 			from "components/gamedev/gameManagement/gameEditScreen"
import ApplicationEditScreen 	from "components/gamedev/applicationManagement/applicationEditScreen"
import ProviderActivity 		from "components/ProviderActivity"
import ProviderMarketplace 		from "components/ProviderMarketplace"
import ProviderEarnings 		from 'components/ProviderEarnings'
import GuildActivity 			from 'components/GuildActivity'
import Loading 					from "components/common/loading"


//const ClearCacheComponent = withClearCache(MainApp);

/*
export default function App({history, ual}) {
	return (
		<ClearCacheComponent ual={ual} history={history}/>
	);
}
*/

export default function App({ history, ual }) {
	const dispatch = useDispatch();

	const info = useSelector(state => state.info);

	const [redirectAddress, setRedirectAddress] = useState('/');
	const [communityModal, setCommunityModal] = useState(null);

	useEffect(() => {
		const url = new URL(window.location.href);

		if (url.search) {
			setRedirectAddress(redirectAddress + url.search);
		}

		dispatch(actions.fetchUser());
		dispatch(actions.getGames());
		dispatch(actions.getConfig());

		//Cirqle Event Tracker PageView event
		setTimeout(() => {
			if (window && window.cqet) {
				try {
					window.cqet('send', 'PageView');
				} catch (error) {
					console.log(error);
				}
			}
		}, 1000);
	}, []);

	useEffect(() => {
		if (ual) {
			dispatch(actions.setUal(ual));
		} else {
			dispatch(actions.setUal(null));
		}
	}, [ual]);

	return (
		<ConnectedRouter history={history}>
			{info !== "" ? (
				<Switch>
					<Layout modal={communityModal} setCommunityModal={setCommunityModal}>
						<Route exact path={process.env.REACT_APP_HOME} component={() =>
							<Community setCommunityModal={setCommunityModal} />
						} />

						{
							info && (
								info.type === process.env.REACT_APP_USER_TYPE
								|| info.type === process.env.REACT_APP_GAMEDEV_TYPE
								|| info.type === process.env.REACT_APP_PROVIDER_TYPE
								|| info.type === process.env.REACT_APP_GUILD_TYPE
							) ? (
								<>
									<Route exact path={process.env.REACT_APP_USER_WALLET} component={() =>
										<Wallet />
									} />
									{ eligbleForWalletMultichainBeta(info) &&
										<Route exact path={process.env.REACT_APP_USER_WALLETMULTICHAIN} component={() =>
											<MultichainWallet />
										} />
									}
									<Route exact path={process.env.REACT_APP_USER_EDIT_PROFILE} component={() =>
										<EditProfile
											info={info}
											fetchNewInfo={() => dispatch(actions.fetchUser())}
										/>
									} />
								</>
							) : (
								<Redirect to={redirectAddress} />
							)
						}

						{

							info && (
								info.type === process.env.REACT_APP_USER_TYPE
								|| info.type === process.env.REACT_APP_GAMEDEV_TYPE
								|| info.type === process.env.REACT_APP_PROVIDER_TYPE
							) && (
								<>
									<Route exact path={process.env.REACT_APP_USER_GAME_ACTIVITY} component={() =>
										<UserActivity
											info={info}
											fetchNewInfo={() => dispatch(actions.fetchUser())}
										/>
									} />
								</>
							)
						}

						{(info && info.type === process.env.REACT_APP_GAMEDEV_TYPE) && (
							<>
								<Route exact path={process.env.REACT_APP_GAMEDEV_GAMES} component={() =>
									<GamedevActivity
										info={info}
										fetchNewInfo={() => dispatch(actions.fetchUser())}
									/>
								} />
								<Route exact path={process.env.REACT_APP_GAMEDEV_MARKETPLACE} component={() =>
									<GamedevMarketplace />
								} />
								<Route exact path={process.env.REACT_APP_GAMEDEV_ADD_GAME} component={() =>
									<NewGame
										info={info}
										fetchNewInfo={() => dispatch(actions.fetchUser())}
									/>
								} />
								<Route exact path={process.env.REACT_APP_GAMEDEV_ADD_APP} component={() =>
									<NewApplication
										info={info}
										fetchNewInfo={() => dispatch(actions.fetchUser())}
									/>
								} />
								<Route exact path={`${process.env.REACT_APP_GAMEDEV_EDIT_GAME}/:game`} component={(props) =>
									<GameEditScreen
										game={props.match.params.game}
										fetchNewInfo={() => dispatch(actions.fetchUser())}
									/>
								} />
								<Route exact path={`${process.env.REACT_APP_GAMEDEV_EDIT_APP}/:app`} component={(props) =>
									<ApplicationEditScreen
										application={props.match.params.app}
										fetchNewInfo={() => dispatch(actions.fetchUser())}
									/>
								} />
							</>
						)}

						{info && info.type === process.env.REACT_APP_PROVIDER_TYPE && (
							<>
								<Route exact path={process.env.REACT_APP_PROVIDER_SERVICES} component={() =>
									<ProviderActivity
										info={info}
										fetchNewInfo={() => dispatch(actions.fetchUser())}
									/>
								} />
								<Route exact path={process.env.REACT_APP_GAMEDEV_MARKETPLACE} component={() =>
									<GamedevMarketplace />
								} />
								<Route exact path={process.env.REACT_APP_PROVIDER_MANAGEMENT} component={() =>
									<ProviderMarketplace />
								} />
								<Route exact path={process.env.REACT_APP_PROVIDER_EARNINGS} component={() =>
									<ProviderEarnings
										info={info}
										fetchNewInfo={() => dispatch(actions.fetchUser())}
									/>
								} />
							</>
						)}

						{info && info.type === process.env.REACT_APP_GUILD_TYPE && (
							<>
								<Route exact path={process.env.REACT_APP_GUILD_SETTINGS} component={() =>
									<GuildActivity />
								} />
							</>
						)}
					</Layout>
				</Switch>
			) : (
				<Loading />
			)}
		</ConnectedRouter>
	);
}
