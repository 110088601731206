export function decodeBase64(encodedUrl) {
    return atob(encodedUrl);
}

export function base64UrlDecode(str) {
    // Replace non-url compatible chars with base64 standard chars
    str = str.replace(/-/g, '+').replace(/_/g, '/');
    // Pad out with standard base64 required padding characters
    const pad = str.length % 4;
    if (pad) {
        if (pad === 1) {
            throw new Error('InvalidLengthError: Input base64url string is the wrong length to determine padding');
        }
        str += new Array(5 - pad).join('=');
    }
    return atob(str);
}

export function decodeJWT(token) {
    if(typeof token !== 'string') {
        throw new Error('Invalid JWT token (not a string)');
    }

    const parts = token.split('.');
    if (parts.length !== 3) {
        throw new Error('Invalid JWT token (not 3 parts)');
    }

    const header = JSON.parse(base64UrlDecode(parts[0]));
    const payload = JSON.parse(base64UrlDecode(parts[1]));
    const signature = parts[2];

    return {
        header,
        payload,
        signature
    };
}
