import React, { useEffect } from 'react';


// this is to be on index
export function EthermailScript() {
    return(
        <script defer>{
            function ({ afid, communityAlias, features }) {
            var p = document.createElement('script');
            p.src = 'https://cdn-email.ethermail.io/sdk/v2/ethermail.js';
            document.body.appendChild(p);
            p.setAttribute('a', afid);
            p.setAttribute('b', communityAlias);
            p.setAttribute('c', features);
            }({
            afid: '67ab85452d1933c6ecc537d2',
            communityAlias: 'qudo',
            features: ['login']
            })
        }</script>
    );
}

// this is to be on the modals
export function EthermailButton() {
    return(
        <ethermail-login 
            widget={`${global.isDev ? process.env.REACT_APP_ETHERMAIL_LOGIN_WIDGET_QA : process.env.REACT_APP_ETHERMAIL_LOGIN_WIDGET_PROD}`} 
            type="wallet" permissions="write">
        </ethermail-login>
    );
}
// + addEtherMailSignInListener( callback ) from /helpers/ethermail.js