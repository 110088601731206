const cssColors = {
    "QUDO_YELLOW": "#FCD902",
    "QUDO_BLUE": "#1867FF",
    "QUDO_DARK_GREY": "#282725",
    "QUDO_MEDIUM_GREY": "#656565",
    "QUDO_LIGHT_GREY": "#9C9C9C",
    "WHITE": "#FFFFFF",
    "BLACK": "#000000"
}

export default cssColors;