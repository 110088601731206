import React, { useState, useEffect }   from 'react';
import { useDispatch }                  from 'react-redux';
import * as actions                     from 'actions';

import Send                 from 'components/common/send';
import Balances             from 'components/common/balances';
import TransactionHistory   from 'components/common/transactionHistory';

export default function Wallet() {
	const dispatch = useDispatch();

	const [render, setRender] = useState(true);

    useEffect(() => {
		dispatch(actions.updateBalance());
    }, []);

	function update() {
        setRender(false);

        setTimeout(() => {
            setRender(true);
        }, 500);

		dispatch(actions.updateBalance());
	}

    return(
        <div className="row">
            <div id="title-box" className="col-12 text-left pl-5">
                <div id="title">
                    Wallet
                </div>
            </div>
			<div className="row col-12 wallet-main-container">
				<div className="col-sm-12 col-md-6 col-xl-4 column">
					<Send update={update}/>
				</div>
				<div className="col-sm-12 col-md-6 col-xl-4 column">
					<Balances/>
                </div>
				<div className="col-sm-12 col-md-12 col-xl-4 column">
					{render && (
						<TransactionHistory/>
					)}
                </div>
            </div>
        </div>
    );
}