import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";

import { topMetaModal } from "./MetaModals";
import { ButtonInput } from "../forms/buttons";
import { PasswordInput, UsernameInput } from "../forms/text";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { EthermailButton } from "../../common/ethermail";

import QUDOServer from '../../../helpers/QUDOServerConnection';
import useDebounce from '../../../helpers/useDebounce';
import { addEtherMailSignInListener, ethermail_eventToToken, decodeEtherMailToken, ethermail_logToken, removeEtherMailSignInListener } from "helpers/ethermail";
import { set } from "helpers/metakeep";

function tooManyRequests() {
    return (
        <>
            <div style={{ fontSize: '22px' }}>
                Too many verification email requests.
            </div>
            <div style={{ marginTop: "14px", fontWeight: 'lighter', fontSize: "20px" }}>
                Please <a href="mailto:qudo.support@blockbastards.io">contact us</a> directly and we will help.
            </div>
        </>
    );

}


export default function Login(props) {
    const [searchUsername, setSearchUsername] = useState('');
    const username = useDebounce(searchUsername, 250);

    const [password, setPassword] = useState();
    const [disabled, setDisabled] = useState(true);

    const [ethermailToken, setEthermailToken] = useState(null);

    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (username && password /* && type */) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [username, password/* , type */]);


    useEffect(() => {
        addEtherMailSignInListener(LoginEthermail);
    }, [])

    useEffect(() => {
        if(!ethermailToken || ethermailToken==="") {
            if (global.isDev) console.debug("[Login Modal] No token, ignoring");
            return;
        }

        if(!props.show) {
            if (global.isDev) console.debug("[Login Modal] Not showing, but has token... clearing");
            setEthermailToken(""); return;
        }
        
        if(global.isDev) ethermail_logToken(ethermailToken);
        
        const decodedToken = decodeEtherMailToken(ethermailToken);
        const payload = decodedToken.payload;
        if( !payload ){ if (global.isDev) console.warn( "No payload!" ); setEthermailToken(""); return; };

        const isValidEmail = payload.ethermail_verified;
        if(!isValidEmail){
            if (global.isDev) console.warn("[Login Modal] Sorry, this email is not valid/verified by EtherMail");
            props.failure("Your EtherMail account isn't verified, please verify it to register on QUDO");
            setEthermailToken(""); return;
        };

        if (global.isDev) console.debug(`[Login Modal] Calling submit()`);
        submit();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ethermailToken, props.show]);
    

    async function submit() {
        if (submitting) return;
        setSubmitting(true);
        if (global.isDev) console.log(`[Login Modal] Submitting login`);
        let _username = username;
        let _password = password;

        if( ethermailToken && ethermailToken !== "" ) { // needs 'random' values, as if they are empty it just fails...
            const decodedToken = decodeEtherMailToken(ethermailToken);
            _username = decodedToken.payload.address;
            _password = decodedToken.payload.origin;
            setEthermailToken("");
        }

        QUDOServer.post(
            `${process.env.REACT_APP_QUDO_SERVER}/auth/login`,
            {
                username: _username,
                password: _password,
                ethermailToken: ethermailToken,
                deviceID: process.env.REACT_APP_WEBAPPUUID
            },
            {
                headers: {
                    "Content-Type": "application/json"
                },
                json: true,
                withCredentials: true
            }
        )
            .then((result) => {
                if(global.isDev) console.log("[Login Modal] Login success", result);
                props.success(`Welcome!`);
                // props.success(`Welcome ${String(username).toLowerCase()}!`);

                // setTimeout(() => {
                //     props.success(null);
                // }, 2500);
            })
            .catch((err) => {
                if(global.isDev) console.log("[Login Modal] Login error", err);
                if (err.errorMessage === 'Account not verified') {
                    props.switchToResendEmail(err.errorMessage, err.data)
                } else if (ethermailToken && ethermailToken !== ""){
                    props.switchToRegister(); 
                    // failed with ether token? maybe we need to register
                    // Login is a bit trickier than Register in case of messages
                } else {
                    props.failure(err.errorMessage)
                }
            })
            .finally(() => {
                setSubmitting(false);
                setEthermailToken("");
            }
        );
    }

    //MARK: EtherMail
    const LoginEthermail = async ( event ) => {
        if(global.isDev) console.log("[Login modal] EtherMail event received");

        const token = ethermail_eventToToken(event);
        if(!token) return;

        setEthermailToken(token);
    }

    useEffect(() => {
        if(global.isDev) console.log(`[Login Modal] <${props.show ? "open" : "closed "}>`);
        
        return;
        /* Don't need this logic, alwyas listening and only trully updated the value if wanted. This worked tho. But other solution is more flexible. Keeping it here for future reference. Nobody ain't reading this XD
        if (props.show) {
            console.log("[login modal] Adding EtherMail listener");
            addEtherMailSignInListener(LoginEthermail);
        } else {
            console.log("[login modal] removing EtherMail listener");
            removeEtherMailSignInListener(LoginEthermail);
        }
        
        return () => {
            console.log("[login modal] removing EtherMail listener (finished)");
            removeEtherMailSignInListener(LoginEthermail);
        };
        */

    }, [props.show]);
    
    //MARK: Render
    return (
        <Modal
            show={props.show}
            onHide={() => {
                props.setShow(null);
                console.log("Hided login modal");
            }}
            dialogClassName="meta-box">
            <Modal.Body className="scroll-100 pt-0">
                {topMetaModal(props.setShow)}
                <div className="row meta-body">
                    <div className="col-12 login-main-text">
                        Hello!
                        <br />
                        Welcome Back
                    </div>
                    <div className="col-12">
                        <Form onSubmit={submit}>
                            <UsernameInput
                                set={setSearchUsername}
                                ignoreValidation={true}
                                minLength={1}
                                maxLength={100}
                                placeholder={"Email / Username"}
                            />

                            <PasswordInput
                                set={setPassword}
                            />

                            <div style={{ marginTop: -12, marginBottom: 12, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <span style={{fontSize: 12}}>or</span>	
                                <EthermailButton />
                            </div>

                            <div
                                id="forgot-password-text"
                                className="row text-center mt-0"
                            >
                                <span
                                    className="col-12 col-sm-6 mt-3"
                                >
                                    <span className="enable-click"
                                        onClick={props.switchToForgotUsername}>
                                        Forgot username?
                                    </span>
                                </span>
                                <span
                                    className="col-12 col-sm-6 mt-3"
                                >
                                    <span className="enable-click"
                                        onClick={props.switchToForgotPassword}>
                                        Forgot password?
                                    </span>
                                </span>
                            </div>

                            {/*
                                props.ual && (
                                    <div
                                        id="forgot-password-text"
                                        className="d-block text-center"
                                    >
                                        <span
                                            className="enable-click"
                                            onClick={props.toggleLoginMode}
                                        >
                                            Login with wallet
                                        </span>
                                    </div>
                                )
                                */}

                            <ButtonInput
                                trigger
                                type="submit"
                                label="Login"
                                className="form-submit-btn text-center"
                                set={submit}
                                disabled={disabled}
                            />
                        </Form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
