export const FETCH_USER = "fetch_user";
export const SET_UAL = "set_ual";
export const FETCH_CHAIN_INFO = "fetch_chain_info";
export const GET_BALANCE = "get_balance";
export const GET_GAMES = "get_games";
export const GET_BLOCK = "get_block";
export const GET_PROFILE = "get_profile";
export const GET_PINFO = "get_player_info";
export const UPDATE_KEYS = "update_keys"
export const GET_PENDING_REWARD = "get_pending_reward"
export const SET_DUMMY_TRANSACTIONS = "get_dummy_transactions"
export const DELETE_DUMMY_TRANSACTIONS = "delete_dummy_transactions"
export const GET_CONFIG = "get_config"
export const SET_WALLET_PAGE = 'set_wallet_page';