import QUDOServer from "../helpers/QUDOServerConnection";

import {
	FETCH_USER,
	SET_UAL,
	GET_GAMES,
	GET_BLOCK,
	GET_BALANCE,
	GET_PENDING_REWARD,
	GET_CONFIG,
	SET_WALLET_PAGE,
} from "./types";

export const fetchUser = (isLogout = false) => {
	return async function (dispatch, getState) {
		if (isLogout) {
			var balance = {
				balance: `0.0000 ${process.env.REACT_APP_TOKENNAME}`,
				savings: `0.0000 ${process.env.REACT_APP_TOKENNAME}`,
				stake: `0.0000 ${process.env.REACT_APP_TOKENNAME}`,
				minimumStake: `0.0000 ${process.env.REACT_APP_TOKENNAME}`
			}

			dispatch({ type: FETCH_USER, payload: null });
			dispatch({ type: GET_BALANCE, payload: balance });
		} else {
			QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/profile/current_userv2
						${isLogout ? '?timestamp=' + (Date.now()) : ''}`, {
				withCredentials: true
			})
				.then(async (user) => {
					//console.log(user);
	
					dispatch({ type: FETCH_USER, payload: user.data });
	
					QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/profile/balance`, {
						withCredentials: true
					})
						.then((balance) => {
							// FETCH BALANCE
							balance = balance.data;
	
							if (!balance) {
								balance = {
									balance: `0.0000 ${process.env.REACT_APP_TOKENNAME}`,
									savings: `0.0000 ${process.env.REACT_APP_TOKENNAME}`,
									stake: `0.0000 ${process.env.REACT_APP_TOKENNAME}`,
									minimumStake: `0.0000 ${process.env.REACT_APP_TOKENNAME}`
								}
							}
	
							dispatch({ type: GET_BALANCE, payload: balance });
						})
						.catch((err) => {
							var balance = {
								balance: `0.0000 ${process.env.REACT_APP_TOKENNAME}`,
								savings: `0.0000 ${process.env.REACT_APP_TOKENNAME}`,
								stake: `0.0000 ${process.env.REACT_APP_TOKENNAME}`,
								minimumStake: `0.0000 ${process.env.REACT_APP_TOKENNAME}`
							}
	
							dispatch({ type: GET_BALANCE, payload: balance });
						});
				})
				.catch((err) => {
					var balance = {
						balance: `0.0000 ${process.env.REACT_APP_TOKENNAME}`,
						savings: `0.0000 ${process.env.REACT_APP_TOKENNAME}`,
						stake: `0.0000 ${process.env.REACT_APP_TOKENNAME}`,
						minimumStake: `0.0000 ${process.env.REACT_APP_TOKENNAME}`
					}
	
					dispatch({ type: FETCH_USER, payload: null });
					dispatch({ type: GET_BALANCE, payload: balance });
				});
		}
	}
}

export const updateBalance = () => {
	return async function (dispatch, getState) {
		//try {
		//console.log('updateBalance()');

		QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/profile/balance`, {
			withCredentials: true
		})
			.then((balance) => {
				// FETCH BALANCE
				balance = balance.data;

				if (!balance) {
					balance = {
						balance: `0.0000 ${process.env.REACT_APP_TOKENNAME}`,
						savings: `0.0000 ${process.env.REACT_APP_TOKENNAME}`,
						stake: `0.0000 ${process.env.REACT_APP_TOKENNAME}`,
						minimumStake: `0.0000 ${process.env.REACT_APP_TOKENNAME}`
					}
				}

				dispatch({ type: GET_BALANCE, payload: balance });
			})
			.catch((error) => {
				dispatch({ type: GET_BALANCE, payload: null });
			});
		//} catch {
		//	dispatch({ type: GET_BALANCE, payload: null });
		//}
	}
}

export const setUal = (ual) => {
	return async function (dispatch) {
		try {
			window.ual = ual;
			dispatch({ type: SET_UAL, payload: ual });
		} catch (error) {
			dispatch({ type: SET_UAL, payload: null });
		}
	}
}

export const getGames = () => {
	return async function (dispatch) {
		try {
			const result = await QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/games/getexistent`);
			dispatch({ type: GET_GAMES, payload: result.data });
		} catch (error) {
			dispatch({ type: GET_GAMES, payload: null });
		}
	}
}

export const getPendingReward = () => {
	return async function (dispatch) {
		try {
			const response = await QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/profile/pendingreward`, {
				withCredentials: true
			});

			dispatch({ type: GET_PENDING_REWARD, payload: response.data });
		} catch (err) {
			dispatch({ type: GET_PENDING_REWARD, payload: null });
		}
	}
}

export const getBlock = () => {
	return async function (dispatch) {
		try {
			const result = await QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/blocks`);

			var data = {
				nextBlock: result.data.nextBlockTimestamp,
				lastBlockReward: result.data.lastActiveBlock.block_reward,
				lastBlock: result.data.lastActiveBlockInfo
			}

			dispatch({ type: GET_BLOCK, payload: data });
		} catch (err) {
			dispatch({ type: GET_BLOCK, payload: null });
		}
	}
}

export const getConfig = () => {
	return async function (dispatch) {
		try {
			const result = await QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/eos/configs`);
			dispatch({ type: GET_CONFIG, payload: result.data });
		} catch (error) {
			dispatch({ type: GET_CONFIG, payload: null });
		}
	}
}

export const setWalletPage = (pageId) => {
    return {
        type: SET_WALLET_PAGE,
        payload: pageId,
    };
};