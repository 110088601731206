import React, { useEffect }         from 'react';
import { useSelector }              from 'react-redux'

import { useEthereum, chains }      from 'helpers/EthereumContext.js' 
import QUDOServer                   from 'helpers/QUDOServerConnection';
import cssColors                    from 'helpers/cssColors'

import WalletTotalBalance           from 'components/walletMultichain/TotalBalance'
import AddressUsernameDisplay       from 'components/walletMultichain/AddressUsernameDisplay' 
import Send                         from 'components/walletMultichain/Send'


export default function WalletBasePage(){

    const eth = useEthereum();
    const user = useSelector(state => state.info);

    useEffect(()=>{
        const url = "/profile/addbaseaccount";
        const lk = user.linkedAccounts;
        const w = lk ? lk.base : undefined;
        if( ! lk || String(w) !== String(eth.walletAddress) ){ // not registed, or registed under different
            if(global.isDev) console.debug(`Expected <${eth.walletAddress}>, got <${w}>`)
            QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER + url}`, {
                account: eth.walletAddress,
            }, { withCredentials: true });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[eth.walletAddress])

    useEffect(()=>{
        eth.switchNetwork(chains.base.chainId)
        .then(()=>{eth.updateBalances();})
        .catch((e)=>{})
    },[])


    return(
        <div className="row col-12 wallet-main-container">
            <div className="col-sm-12 col-md-6 col-xl-4 column" style={{display: "flex", flexDirection: "column", gap: "16px"}}>
                <WalletTotalBalance 
                    Balance={eth.balances[chains.base.chainId]}
                    detailColor={cssColors.QUDO_DARK_GREY}
                />
                <AddressUsernameDisplay 
                    name={eth.walletAddress}
                />
            </div>

            <div className="col-sm-12 col-md-6 col-xl-4 column">
                <Send 
                    update={eth.updateBalances}
                    chainId={chains.base.chainId}
                    detailColor={cssColors.QUDO_DARK_GREY}
                />
            </div>

            <div className="col-sm-12 col-md-12 col-xl-4 column">
            </div>
        </div>
    )
}