import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import cssColors from "helpers/cssColors";

/**
* @param {Array<Object>} chartData - An array of objects, where each object represents a section of the pie chart.
* @param {string} chartData[].color - The hexadecimal color code for the section.
* @param {string} chartData[].name - The name of the section.
* @param {number} chartData[].value - The numerical value of the section.
*
* @returns {React.Component} A React component that renders a Highcharts pie chart.
*
* @example
* const chartData = [
*   { name: 'Section A', value: 30, color: '#4CAF50' },
*   { name: 'Section B', value: 50, color: '#2196F3' },
*   { name: 'Section C', value: 20, color: '#F44336' },
* ];
*
* <WalletPieChart chartData={chartData} />
*/
export default function WalletPieChart({
  chartData,
  title,
}) {
  
  const isMobile = ( window && window.innerWidth < 768);

  if (!chartData || !Array.isArray(chartData) || chartData.length === 0) {
    return <p>No data available for the chart.</p>;
  }

  const seriesData = chartData.filter(i=>i.value>0).map((item) => ({
    name: item.name,
    y: item.value,
    color: item.color,
  }));

  // Highcharts options
  const chartOptions = {
    chart: {
      type: "pie",
      backgroundColor:"#00000000"
    },
    title: {
      text: "", 
    },
    series: [
      {
        name: "Balance",
        colorByPoint: true,
        data: seriesData,
      },
    ],
  };

  return (
    <div style={{display:"flex", flexDirection:"column", gap:`${isMobile?"8px":"16px"}`}}> {/* it is 52px... but it's ugly, I refuse*/}
        <span>
            {title}
        </span>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        <div style={{display:"flex", flexDirection:"row", gap:"10px", flexWrap:"wrap"}}>
          {
            chartData.map(({ name, color }) => {
              return(
              <div key={name} style={{display:"flex", alignItems:"center"}}>
                <div style={{height:`${isMobile?"20px":"28px"}`, width:`${isMobile?"20px":"28px"}`, backgroundColor:color, marginRight:"4px"}} />
                <div style={{fontSize:`${isMobile?"12px":"14px"}`, color:cssColors.QUDO_MEDIUM_GREY}}>
                  {name}
                </div>
              </div>
              )
            })
          }
        </div>
    </div>
  );
};
