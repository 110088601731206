const linkedAccountsAvailableFields = ['tEVM', 'base', 'steam']
// tEVM base - from 
export function getLinkedAccount( userInfo, field, fallback = '' ){
    if( ! linkedAccountsAvailableFields.includes( field ) )
        console.warn(`get Linked account <${field}> not possible in <${JSON.stringify(linkedAccountsAvailableFields)}>`)

    if( typeof(userInfo) !== 'object' ){
        console.error(`userInfo should be <object>, not <${typeof(userInfo.linkedAccounts)}> `)
        throw new Error("bad userInfo type")
    }

    if( typeof(field) !== 'string' && typeof(field) !== 'number' ){
        console.error(`string should be <string|number>, not <${typeof(field)}> `)
        throw new Error("bad field type")
    }

    if( typeof(userInfo.linkedAccounts) !== 'object' )
        return fallback;

    if( userInfo.linkedAccounts[field] )
        return userInfo.linkedAccounts[field]

    return fallback
}

const UserInfo = {
    getLinkedAccount,
};

export default UserInfo;