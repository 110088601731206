import React                from 'react';
import { useSelector }      from 'react-redux'
import { OverlayTrigger, Tooltip }  from "react-bootstrap";
import { useEthereum }              from 'helpers/EthereumContext.js'

const arrowBack = require("images/definitive/icn-arrow-back.png");
const qudoSymbol = require("images/QUDO_Logo.svg")


const addTokenTooltip = (
    <Tooltip placement="right" className="">
        <span className="text-light"><small>
            {`Add Token to the wallet`}
        </small></span>
    </Tooltip>
);


export default function WalletTitle({ switchPage, title }) {

    const eth = useEthereum();
    const walletPage = useSelector((state) => state.chosenWalletPage );

    return (
        <div id="title-box" className="col-12 text-left pl-5">
            <div id="title">
                { switchPage &&
                    <img
                        src={arrowBack}
                        className=""
                        style={{ cursor: "pointer" }}
                        onClick={switchPage}
                        alt="arrow back"
                    />
                }
                {
                    title
                }
                { walletPage > 1 &&
                    <OverlayTrigger 
                        placement={ `${ "right" }` }
                        overlay={ addTokenTooltip }
                    >
                        <img
                            src={qudoSymbol}
                            className=""
                            style={{ cursor: "pointer", width:'24px', height:'24px', marginLeft:"8px" }}
                            onClick={()=>{eth.addToken()}}
                            alt="arrow back"
                        />
                    </OverlayTrigger>    
                }

            </div>
        </div>
    );
}